<template>
  <div class="wrap treeWrap">
    <div class="left">
      <div class="title">问答类型</div>
      <el-tree
        ref="tree"
        v-loading="treeLoading"
        :data="data"
        :props="defaultProps"
        node-key="categoryId"
        :highlight-current="true"
        :expand-on-click-node="false"
        @node-click="treeClick"
      >
        <span slot-scope="scope" class="custom-tree-node">
          <div class="label">
            <span class="name">{{ scope.data.categoryName }}</span>
            <div v-show="scope.data.categoryId == show.categoryId">
              <el-dropdown trigger="click" @command="handleCommand">
                <i class="el-icon-s-tools"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">新增同级类型</el-dropdown-item>
                  <el-dropdown-item command="2">编辑类型</el-dropdown-item>
                  <el-dropdown-item command="3">删除类型</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </span>
      </el-tree>
    </div>
    <div class="right">
      <page-header @search="search" />
      <div class="table">
        <div class="btn">
          <el-button icon="el-icon-plus" type="primary" size="small" @click="add">新增</el-button>
          <el-button
            :disabled="multipleSelection.length === 0"
            size="small"
            type="default"
            @click="del('批量')"
            >删除</el-button
          >
        </div>
        <Table
          ref="table"
          :table-data="tableData"
          :form="form"
          :total="total"
          :loading="loading"
          @handleSelectionChange="handleSelectionChange"
          @edit="edit"
          @del="del"
        />
        <Pages
          :current-page="form.pageNumber"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      :title="isAdd ? '新增问答' : '编辑问答'"
      :visible.sync="dialogFormVisible"
      :before-close="cancel"
      width="40%"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item label="问答类型" prop="categoryId">
          <el-select v-model="ruleForm.categoryId" style="width: 300px" placeholder="请选择">
            <el-option
              v-for="(n, i) in data"
              :key="i"
              :label="n.categoryName"
              :value="n.categoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题" prop="question">
          <el-input
            v-model="ruleForm.question"
            size="small"
            style="width: 300px"
            placeholder="请输入问题"
          ></el-input>
        </el-form-item>

        <el-form-item label="回答" prop="answer">
          <el-input
            v-model="ruleForm.answer"
            style="width: 300px"
            type="textarea"
            :rows="8"
            maxlength="500"
            show-word-limit
            placeholder="请输入回答内容"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button :loading="btnLoading" size="small" type="primary" @click="ok">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="type == 1 ? '新增类型' : '编辑类型'"
      :visible.sync="scenVisible"
      width="500px"
      :before-close="cateCancel"
    >
      <el-form
        ref="cateForm"
        :model="cateForm"
        :rules="cateRules"
        label-width="120px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item label="类型名称" prop="categoryName">
          <el-input
            v-model="cateForm.categoryName"
            size="small"
            style="width: 260px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序数字">
          <el-input
            v-model="cateForm.sortNum"
            size="small"
            style="width: 260px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cateCancel">取 消</el-button>
        <el-button :loading="cateLoading" size="small" type="primary" @click="cateOk"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import {
  questionAnswerGetList,
  questionAnswerCateList,
  questionAnswerCatedelete,
  questionAnswerCateSave,
  questionAnswerSave,
  questionAnswerDelete,
} from '@/api'

export default {
  components: {
    pageHeader,
    Table,
    Pages,
  },
  data() {
    return {
      treeLoading: false,
      tableData: [],
      total: 10,
      multipleSelection: [],
      data: [],
      defaultProps: {
        children: 'children',
        label: 'categoryName',
      },
      isAdd: false,
      ruleForm: {
        categoryId: '',
      },
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      dialogFormVisible: false,
      rules: {
        categoryId: [{ required: true, message: '请选择', trigger: 'change' }],
        question: [{ required: true, message: '请输入问题', trigger: 'change' }],
        answer: [{ required: true, message: '请输入回答内容', trigger: 'change' }],
      },
      cateRules: {
        categoryName: [{ required: true, message: '请输入类型名称', trigger: 'change' }],
      },
      show: {},
      scenVisible: false,
      cateForm: {},
      cateLoading: false,
      btnLoading: false,
      type: '',
    }
  },
  mounted() {
    this.getTableData()
    this.questionAnswerCateList()
  },
  methods: {
    cateOk() {
      this.$refs.cateForm.validate((valid) => {
        if (valid) {
          this.cateLoading = true
          this.cateForm.parentId = this.show.parentId
          if (this.type === 2) {
            this.cateForm.categoryId = this.show.categoryId
          }
          questionAnswerCateSave({ data: this.cateForm })
            .then((res) => {
              this.cateForm = {}
              this.$refs.cateForm.resetFields()
              this.cateLoading = false
              this.scenVisible = false
              this.show = {}
              this.questionAnswerCateList()
              this.$message({
                message: '保存成功',
                type: 'success',
              })
            })
            .catch(() => {
              this.cateLoading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cateCancel() {
      this.cateForm = {}
      this.$refs.cateForm.resetFields()
      this.scenVisible = false
    },
    handleCommand(command, data) {
      command = Number(command)
      if (command === 3) {
        this.$confirm('确认删除该条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            questionAnswerCatedelete({
              data: {
                categoryId: this.show.categoryId,
              },
            }).then((res) => {
              this.questionAnswerCateList()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
          })
          .catch(() => {})
      } else {
        this.type = command
        this.scenVisible = true
        if (command === 2) {
          this.$set(this.cateForm, 'categoryName', this.show.categoryName)
          this.$set(this.cateForm, 'sortNum', this.show.sortNum)
        }
      }
    },
    questionAnswerCateList() {
      this.treeLoading = true
      questionAnswerCateList({ data: {} }).then((res) => {
        this.treeLoading = false
        this.data = res.data
      })
    },
    treeClick(data) {
      console.log(data)
      this.show = data
      this.form.data.categoryId = data.categoryId
      this.getTableData()
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = { ...data }
      if (JSON.stringify(data) === '{}') {
        this.$refs.tree.setCurrentKey(null)
        this.show = {}
      }
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      questionAnswerGetList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    handleNodeClick(data) {
      console.log(data)
    },
    edit(row) {
      this.isAdd = false
      this.dialogFormVisible = true
      this.$set(this.ruleForm, 'categoryId', row.categoryId)
      this.$set(this.ruleForm, 'question', row.question)
      this.$set(this.ruleForm, 'answer', row.answer)
      this.$set(this.ruleForm, 'questionAnswerId', row.questionAnswerId)
    },
    del(row) {
      this.$confirm(
        row === '批量'
          ? `确认删除所选的${this.multipleSelection.length}数据?`
          : '确认删除该条数据?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
        .then(() => {
          let questionAnswerIds = []
          if (row === '批量') {
            this.multipleSelection.forEach((e) => {
              questionAnswerIds.push(e.questionAnswerId)
            })
          } else {
            questionAnswerIds = [row.questionAnswerId]
          }
          questionAnswerDelete({
            data: {
              questionAnswerIds,
            },
          }).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.form.pageNumber = 1
            this.getTableData()
          })
        })
        .catch(() => {})
    },
    add() {
      this.isAdd = true
      this.dialogFormVisible = true
      if (this.show.categoryId) {
        this.ruleForm.categoryId = this.show.categoryId
      }
    },
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = {}
          data.categoryId = this.ruleForm.categoryId
          data.question = this.ruleForm.question
          data.answer = this.ruleForm.answer
          if (this.ruleForm.questionAnswerId) {
            data.questionAnswerId = this.ruleForm.questionAnswerId
          }
          this.btnLoading = true
          questionAnswerSave({ data: { ...data } })
            .then((res) => {
              this.ruleForm = {}
              this.btnLoading = false
              this.$refs.ruleForm.resetFields()
              this.dialogFormVisible = false
              this.form.pageNumber = 1
              this.getTableData()
              this.$message({
                message: '保存成功',
                type: 'success',
              })
            })
            .catch(() => {
              this.btnLoading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cancel() {
      this.ruleForm = { categoryId: '' }
      this.$refs.ruleForm.resetFields()
      this.dialogFormVisible = false
    },
    toggleSelection(rows) {
      this.$refs.table.toggleSelection(rows)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      console.log('当前选择', val)
      this.multipleSelection = val
    },
    exportTable() {},
  },
}
</script>
<style lang="less" scoped></style>
